import { Route, Router } from "@solidjs/router";
import { ThemeProvider } from "@suid/material";
import {
  Component,
  createRenderEffect,
  createSignal,
  ErrorBoundary,
  lazy,
} from "solid-js";
import { useRootTheme } from "./material/mui.js";
import {
  Provider as ClientProvider,
  createMastoClientFor,
  type Session,
} from "./masto/clients.js";
import { $accounts } from "./accounts/stores.js";
import { useStore } from "@nanostores/solid";

const AccountSignIn = lazy(() => import("./accounts/SignIn.js"));
const AccountMastodonOAuth2Callback = lazy(
  () => import("./accounts/MastodonOAuth2Callback.js"),
);
const TimelineHome = lazy(() => import("./timelines/Home.js"));
const Settings = lazy(() => import("./settings/Settings.js"));
const TootBottomSheet = lazy(() => import("./timelines/TootBottomSheet.js"));

const Routing: Component = () => {
  return (
    <Router>
      <Route path="/" component={TimelineHome}>
        <Route path=""></Route>
        <Route path="/settings" component={Settings}></Route>
        <Route path="/:acct/:id" component={TootBottomSheet}></Route>
      </Route>
      <Route path={"/accounts"}>
        <Route path={"/sign-in"} component={AccountSignIn} />
        <Route
          path={"/oauth2/mastodon"}
          component={AccountMastodonOAuth2Callback}
        />
      </Route>
    </Router>
  );
};

const App: Component = () => {
  const theme = useRootTheme();
  const accts = useStore($accounts);
  const clientStore = createSignal<Session[]>([]);

  createRenderEffect(() => {
    const [, setClients] = clientStore;
    setClients(
      accts().map((x) => ({ account: x, client: createMastoClientFor(x) })),
    );
  });

  const UnexpectedError = lazy(() => import("./UnexpectedError.js"));

  return (
    <ErrorBoundary
      fallback={(err, reset) => {
        console.error(err);
        return <UnexpectedError error={err} />;
      }}
    >
      <ThemeProvider theme={theme()}>
        <ClientProvider value={clientStore}>
          <Routing />
        </ClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
