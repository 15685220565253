import { Theme, createTheme } from "@suid/material/styles";
import { deepPurple, amber } from "@suid/material/colors";
import { Accessor } from "solid-js";

export function useRootTheme(): Accessor<Theme> {
  return () =>
    createTheme({
      palette: {
        primary: {
          main: deepPurple[500],
        },
        secondary: {
          main: amber.A200,
        },
      },
    });
}
